import React, {useReducer, useEffect} from "react";
import { validate } from "../../util/validators";

import "./Styles/Input.css";

const inputReducer = (state, action) =>{
    switch (action.type){
        case 'CHANGE':
            return{ // return the new state
                ...state,
                value: action.val,
                isValid: validate(action.val, action.validators)
            };
        case 'TOUCH':
                return{
                    ...state,
                    isTouched: true
                }
        default:
            return state
    }
}

const Input = (props) =>{

    const [inputState, dispatch] = useReducer(inputReducer, {
        value: props.initialValue || '', 
        isValid: props.initialValid || '', 
        isTouched: false
    });

    const {id, onInput} = props;
    const {value, isValid} = inputState;

    useEffect(() =>{
        onInput(id, value, isValid);
    }, [id, value, isValid, onInput]);

    const changeHandler = (event) => {
        dispatch({
            type: 'CHANGE',
            val: event.target.value,
            validators: props.validators
        })
    };

    const touchHandler = () =>{
        dispatch({
            type:  'TOUCH'
        })
    }

    const element = props.element === 'input' ? <input 
                                                    id={props.id} 
                                                    type={props.type} 
                                                    placeholder={props.placeholder}
                                                    onChange={changeHandler}
                                                    onBlur={touchHandler}
                                                    value={inputState.value}
                                                /> 
                                              : <textarea 
                                                    id={props.id} 
                                                    rows={props.rows || 3} 
                                                    onChange={changeHandler} 
                                                    onBlur={touchHandler}
                                                    value={inputState.value}
                                                />
    return(
        <div className={`form-control ${!inputState.isValid && inputState.isTouched ? 'form-control--invalid' : null}`}>
            <label htmlFor={props.id}>{props.label}</label>
            {element}
            {!inputState.isValid && inputState.isTouched ? <p>{props.errorText}</p> : null}
        </div>
    )
}

export default Input;