import React from "react";

const CustomizedPlans = () =>{
    return(
        <div>
            <h1>Customized Plans</h1>
        </div>
    )
}

export default CustomizedPlans;